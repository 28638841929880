<template>
  <div class="skeleton-wrapper">
    <div v-for="n in count" :key="n">
      <div class="content">
        <div class="content-left">
          <div class="skeleton-item content-child"></div>
        </div>
        <div class="content-right">
          <div class="skeleton-item"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MethodsLoader",
  props: {
    count: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style scoped src="./index.css"></style>
